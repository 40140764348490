import { render, staticRenderFns } from "./nationalityInfo.vue?vue&type=template&id=6f135c2b&scoped=true"
import script from "./nationalityInfo.vue?vue&type=script&lang=js"
export * from "./nationalityInfo.vue?vue&type=script&lang=js"
import style0 from "./nationalityInfo.vue?vue&type=style&index=0&id=6f135c2b&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f135c2b",
  null
  
)

export default component.exports